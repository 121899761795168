
.itemlist {
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(172px,auto));
    gap: 2.5rem;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width : 960px) {
    .itemlist {
        gap: 0.5rem;
    }
}


.itemlist img {
    border: 1px solid rgba(211,211,211,0.7)
}