.header {
    width: 100%;
    height: 80px;
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;

}


.header-icon {
    padding-left: 20px;
    padding-top: 4px;
}

.header-search-bar {
    margin:auto;
}

.header-search-bar-transform {
    transform: translate(0px,20vh);
}

.header-search-bar-input {
    position: relative;
}

.header-search-bar-input img {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 16px;
    left: calc(100% - 40px);

    cursor: pointer;
}

/*Cancel button style*/
#Search::-webkit-search-cancel-button{
    position:relative;
    right:55px; 
    cursor: pointer;
    
}

.header-search-bar-input input {
    background: rgb(240,240,240);
    width: 40vw;
    min-width: 200px;
    max-width: 700px;
    height:52px;

    padding-left: 25px;
    border: 0px;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.6); /*ridge effect*/
    font-size: large;
    outline: none;
}

.header-github {
    padding-right: 20px;
}



