* {
    box-sizing: border-box;
    margin: auto;
    padding: 0;
}

/*fix scroll bar width change*/
@media screen and (min-width: 769px) {
    html {
    overflow-y: scroll;
    }
}


.container{
    text-align: center;
    height: 100vh;
    
    background-image: url("../img/background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    
}

.container-loaded {
    background-image: none;
    background-color: white;
}

.content-container {
    max-width: 1800px;
    padding-top: 100px;
    padding-left: 25px;
    padding-right: 25px;
}

@media screen and (max-width: 960px) {
    .content-container {
        padding-left: 0px;
        padding-right: 0px;
    }
}

